<template>
    <div id="product">
        <div class="content">
            <div class = "searchBox">
                <div class = "box">
                    <input type = "text" placeholder="搜索：例如输入商品型号9326" v-model = "searchStr"/>
                    <img :src = "require('@/assets/images/phone/icon_search.png')" @click = "toProductSearch"/>
                </div>
            </div>
            <div class="banner" v-if="banner.length > 0">
                <img :src="staticPath + (isEn ? banner[0].enImageUrl : banner[0].cnImageUrl)" @click="goto(banner[0].redirectUrl)" alt="">
            </div>
            <div style="visibility: visible" :class="['products', 'animate__animated', 'animate__fadeInUp']">
                <div class="topic" v-if="data && data.cpxl">
                    <i class="icon-line"></i>
                    <h2>{{ isEn ? 'Products' : '全部产品' }}</h2>
                </div>
                <div class = "searchBoxRight">
                    <ul class="items">
                        <li v-for="(item, index) in data.cpxl" :key="index" :class="[productSeriesIdx === index ? 'actived' : '']" @click="changeProductItem(index, item)">
                            {{ isEn ? item.enName : item.cnName }}
                        </li>
                    </ul>
                    <div class = "box" @keyup.enter="toProductSearch">
                        <img :src = "require('@/assets/images/icon_search.png')" @click = "toProductSearch"/>
                        <input type = "text" placeholder="商品型号:9326" v-model = "searchStr"/>
                    </div>
                </div>
                <div class="list">
                    <!--<div class="search-bar">
                        <span>
                          <i class="icon-search" @click=""></i>
                          <input type="text" maxlength="24" v-model="productModel" placeholder="商品型号">
                        </span>
                    </div>-->
                    <ul v-if="data.cpxl && productSeries.length > 0">
                        <li v-if = "screenWidth <= 750 ? index < 6 : index >= 0" v-for="(item, index) in productSeries" :key="index">
                            <div class="" @click="toProductList(item.id)">
                                <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" alt />
                                <h4>{{ isEn ? item.enName : item.cnName }}</h4>
                            </div>
                        </li>
                    </ul>
                    <span @click="toProductList('')" class="btn-more" v-if="data.cpxl">{{ $t('lang.see_more') }}</span>
                </div>
            </div>
            <div :style="{'visibility': aniObj.creativityVisible ? 'visible' : 'hidden'}" :class="['creativity', 'animate__animated', aniObj.creativity ? 'animate__fadeInUp' : '']" ref="creativity">
                <div class="topic" v-if="data && data.cyzs">
                    <span class="icon-line"></span>
                    <h2>{{ isEn ? data.cyzs.enName : data.cyzs.cnName }}</h2>
                </div>
                <div class="video">
                    <div class="video-items" v-if="data.cyzs && data.cyzs.data.length > 0">
                        <button :class="['btn', index === videoIdx ? 'video-selected' : '']" v-for="(item, index) in data.cyzs.data" :key="index" @click="videoIdx = index">{{ index + 1 > 10 ? index : '0' + (index + 1) }}</button>
                    </div>
<!--                    <video :src="data.cyzs.data[videoIdx].files[0].templateUrl" width="80%" height="100%" controls v-if="data.cyzs"></video>-->
                    <video :src="data.cyzs.data[videoIdx].files.length > 0 ? staticPath + data.cyzs.data[videoIdx].files[0].fileUrl : ''" width="80%" height="100%" controls v-if="data.cyzs.data.length > 0"></video>
                </div>
            </div>
            <div :style="{'visibility': aniObj.shoppingVisible ? 'visible' : 'hidden'}" :class="['shopping-online', 'animate__animated', aniObj.shopping ? 'animate__fadeInUp' : '']" ref="shopping">
                <div class="topic" v-if="data && data.wsgw">
                    <span class="icon-line"></span>
                    <h2>{{ isEn ? data.wsgw.enName : data.wsgw.cnName }}</h2>
                </div>
                <div class="stores" v-if="data && data.wsgw">
                    <div class="store" v-for="(item, index) in data.wsgw.data" :key="index">
                        <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" alt="">
                        <h2>{{ isEn ? item.enName : item.cnName }}</h2>
                        <p>{{ item.templateUrl }}</p>
                        <a :href="item.templateUrl" target="_blank">{{ $t('lang.enter') }}</a>
                    </div>
                </div>
            </div>
            <div :style="{'visibility': aniObj.downloadVisible ? 'visible' : 'hidden'}" :class="['download', 'animate__animated', aniObj.download ? 'animate__fadeInUp' : '']" ref="download">
                <div class="topic" v-if="data && data.zyxz">
                    <span class="icon-line"></span>
                    <h2>{{ isEn ? data.zyxz.enName : data.zyxz.cnName }}</h2>
                </div>
                <ul v-if="data && data.zyxz">
                    <li v-for="(item, index) in data.zyxz.data" :key="index">
                        <div class="pic">
                            <img :src="staticPath + (isEn ? item.enLogo : item.cnLogo)" alt="">
                        </div>
                        <h2>{{ isEn ? item.enName : item.cnName }}</h2>
                        <span class="icon-more" @click="toDownload(item.templateUrl)"></span>
<!--                        <router-link :to="item.templateUrl" class="icon-more"></router-link>-->
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import {urls, staticPath, banbaoUrl} from '@/lib/common'
    import axios from 'axios'
    export default {
        name: "Product",
        components: {},
        data() {
            return {
                staticPath,
                data: "",
                isEn: false,
                banner: [],
                productModel: '',
                videoIdx: 0,
                creativityScrollTop: '',
                shoppingScrollTop: '',
                downloadScrollTop: '',
                aniObj: {
                    creativityVisible: false,
                    creativity: false,
                    shoppingVisible: false,
                    shopping: false,
                    downloadVisible: false,
                    download: false
                },
                searchStr:'',
                productSeriesIdx: 0,
                productSeries: [],

                screenWidth:document.body.clientWidth
            };
        },
        watch: {
            "$i18n.locale": function(val) {
                this.isEn = val === 'en' ? true : false;
            }
        },
        methods: {
            goto(path) {
                if (path.indexOf('http') !== -1) {
                    window.open(path)
                } else {
                    this.$router.push({
                        path
                    })
                }
            },
            getTemplate(id) {
                this.$http.get(urls.getProductTemp + id).then(res => {
                    if (res.data.status === 200) {
                        this.data = res.data.data;
                        this.getProductSeries();
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getProductSeries() {
                const sid = this.data.cpxl[this.productSeriesIdx].id
                this.$http.get(urls.getNavChild + sid).then(res => {
                    console.log(res)
                    if (res.data.status === 200) {
                        this.productSeries = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getBanner(id) {
                this.$http.get(urls.getBannerList + id).then(res => {
                    if (res.data.status === 200) {
                        this.banner = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            toProductSearch() {
                const query  = {
                    searchStr: this.searchStr,
                    series: this.data.cpxl
                }
                if (this.searchStr) {
                    sessionStorage.setItem('productSearch', JSON.stringify(query));
                    this.$router.push({
                        name: 'ProductList',
                        params: {
                            parentId: encodeURIComponent(btoa(this.data.cpxl[this.productSeriesIdx].id))
                        }
                    })
                } else {
                    this.$message.warning('请输入商品名称/型号')
                }
            },
            toProductList(id) {
                if (id) {
                    this.$router.push({
                        name: 'ProductList',
                        params: {
                            parentId: encodeURIComponent(btoa(this.data.cpxl[this.productSeriesIdx].id))
                        },
                        query: {
                            n: encodeURIComponent(btoa(id))
                        }
                    })
                } else {
                    this.$router.push({
                        name: 'ProductList',
                        params: {
                            parentId: encodeURIComponent(btoa(this.data.cpxl[this.productSeriesIdx].id))
                        }
                    })
                }
            },
            changeProductItem(index, item) {
                this.productSeriesIdx = index;

                if(item.type === 0) this.$router.push({path: item.templateUrl});
                else this.getProductSeries();
            },

            // 监听页面滚动
            getScrollTop() {
                var scroll_top = 0;
                if (document.documentElement && document.documentElement.scrollTop) {
                    scroll_top = document.documentElement.scrollTop;
                } else if (document.body) {
                    scroll_top = document.body.scrollTop;
                }
                return scroll_top;
            },
            listenPageScrollFun() {
                document.addEventListener('scroll', this.zoomControl, true)
            },
            zoomControl() {
                var diffValue = -(document.body.clientHeight * 0.5);
                this.winScrollHeight = this.getScrollTop();
                if (!this.aniObj.creativity && this.winScrollHeight >= this.$refs.creativity.offsetTop + diffValue) {
                    this.aniObj.creativityVisible = true;
                    this.aniObj.creativity = true;
                }
                if (!this.aniObj.shopping && this.winScrollHeight >= this.$refs.shopping.offsetTop + diffValue) {
                    this.aniObj.shoppingVisible = true;
                    this.aniObj.shopping = true;
                }
                if (!this.aniObj.download && this.winScrollHeight >= this.$refs.download.offsetTop + diffValue) {
                    this.aniObj.downloadVisible = true;
                    this.aniObj.download = true;
                }
            },
            toDownload(path) {
                const token = this.$cookies.get('app.token', {domain: banbaoUrl, path: ''}) || sessionStorage.getItem('token');
                if (token) {
                    this.$router.push({
                        path
                    })
                } else {
                    this.$message.error('该栏目需要登录');

                    let info = {
                        pathName: this.$router.history.current.name,
                        params: this.$router.history.current.params
                    }

                    if (this.$router.history.current.query && this.$router.history.current.query.n) {
                        info.query = {
                            n: this.$router.history.current.query.n
                        };
                    }
                    sessionStorage.setItem('prevPath', JSON.stringify(info))
                    setTimeout(() => {
                        this.$router.push({
                            path: '/login'
                        })
                    }, 2000)
                }
            }
        },
        beforeDestroy() {
            document.removeEventListener('scroll', this.zoomControl, true)
        },
        mounted() {
            const self = this;
            const navId = atob(decodeURIComponent(self.$route.params.navigationId));
            self.listenPageScrollFun()

            axios.all([self.getBanner(navId), self.getTemplate(navId)]).then(
                axios.spread(function(res1, res2){
                    console.log('所有请求完成', res1, res2)
                    self.creativityScrollTop = self.$refs.creativity.offsetTop;
                    self.shoppingScrollTop = self.$refs.shopping.offsetTop;
                    self.downloadScrollTop = self.$refs.download.offsetTop;
                }))
        }
    };

</script>

<style lang="less" scoped>
    #product {
        .navbar {
            position: relative;
        }
        .banner {
            width: 1920px;
            height: 600px;
            margin: 0 auto;
        }
        .content {
            background: url("../../assets/images/products/product_bg.png") no-repeat top center;
            background-size: 100% 3650px;
            position: relative;
            >div:not(.banner) {
                width: 1300px;
                margin: 80px auto 60px;
            }

            .searchBox{
                display: none;
            }

            .banner {
                cursor: pointer;
            }
            >div:not(.products) {
                .topic {
                    margin-bottom: 3vw;
                    h2 {
                        position: relative;
                        font-size: 28px;
                        &::before {
                            content: "";
                            display: block;
                            top: 4px;
                            width: 4em;
                            border-bottom: 1px solid #ff4b7c;
                            margin-bottom: 10px;
                        }
                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            left: 0;
                            top: -4px;
                            width: 34px;
                            height: 9px;
                            background: #ff4b7c;
                            border-radius: 10px;
                        }
                    }
                }
            }
            .products {
                .topic {
                    text-align: center;
                    font-size: 28px;
                    margin-bottom: 40px;
                    .icon-line {
                        display: block;
                        width: 34px;
                        height: 9px;
                        background: #ff4b7c;
                        border-radius: 10px;
                        margin: 20px auto 15px;
                    }
                }
                .searchBoxRight{
                    padding-bottom: 30px;
                    >ul {
                        float: left;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 16px;
                        li {
                            height: 60px;
                            line-height: 60px;
                            text-align: center;
                            border-radius: 10px;
                            cursor: pointer;
                            color: #34254C;
                            margin-right: 15px;
                            width: 180px;
                            background-color: rgba(235, 251, 255, .8);
                            transition: all .3s linear;
                            box-shadow: 0 10px 10px 2px rgba(204, 243, 255, 0.7);
                            &.actived {
                                position: relative;
                                background: linear-gradient(to right, #FF4B7C, #DA0B16);
                                color: #fff;
                                box-shadow: 0 10px 15px 1px rgba(239, 129, 145, 0.5);
                                &::after {
                                    content: '';
                                    position: absolute;
                                    left: 50%;
                                    bottom: -30px;
                                    margin-left: -10px;
                                    border: 10px solid transparent;
                                    border-top-color: #34254C;
                                }
                            }
                        }
                    }
                    .box{
                        float: right;
                        display: flex;
                        align-items: center;
                        width: 366px;
                        border:1px solid #8bcbe5;
                        height: 60px;
                        border-radius: 33px;
                        img{
                            width: 34px;
                            height: 34px;
                            margin-left: 26px;
                            margin-right: 20px;
                            cursor: pointer;
                        }
                        input{
                            font-size: 18px;
                            height: 60px;
                            padding-left: 10px;
                            border-left: 1px solid #CFEAF4;
                        }                    
                    }
                    &::after{
                        content: '';
                        display: block;
                        clear: both;
                    }
                }
                .list {
                    &::after {
                        content: '';
                        display: block;
                        clear: both
                    }
                    .search-bar {
                        text-align: right;
                        height: 3vw;
                        line-height: 3vw;
                        margin-bottom: 3vw;
                        >span {
                            position: relative;
                            &::before {
                                content: '';
                                display: inline-block;
                                height: 3.2vw;
                                border-right: 1px solid #8BCBE5;
                                position: absolute;
                                left: 3.2vw;
                            }
                            display: inline-block;
                            border: 1px solid #8BCBE5;
                            border-radius: 3vw;
                            .icon-search {
                                display: inline-block;
                                width: 2vw;
                                height: 2vw;
                                background: url("../../assets/images/icon_search.png") no-repeat center center;
                                background-size: 100%;
                                position: relative;
                                top: .6vw;
                                left: .7vw;
                                cursor: pointer;
                            }
                            input {
                                font-size: 1.25vw;
                                padding-left: 2vw;
                                padding-right: 1vw;
                            }
                        }
                    }
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 2vw;
                        li {
                            width: 21%;
                            cursor: pointer;
                            margin: 0 5% 4% 0;
                            box-shadow: 0 10px 40px rgba(40, 40, 40, 0.15);
                            background: #fff;
                            line-height: 24px;
                            border-radius: 10px;
                            &:nth-child(4n) {
                                margin-right: 0;
                            }
                            img {
                                width: 273px;
                                height: 273px;
                                border-radius: 10px 10px 0 0;
                            }
                            h4 {
                                text-align: center;
                                margin: 10px 0 20px;
                                font-size: 18px;
                                font-weight: normal;
                            }
                            .icon {
                                display: inline-block;
                                width: 1.45vw;
                                height: 1.07vw;
                                background: url("../../assets/images/icon_arrow.png") no-repeat 100%
                                100%;
                                background-size: contain;
                                cursor: pointer;
                                margin: 10px 20px 20px;
                            }
                        }
                    }
                }
                .btn-more {
                    display: block;
                    width: 220px;
                    height: 70px;
                    line-height: 70px;
                    margin: 10px auto;
                    font-size: 20px;
                    border-radius: 50px;
                    text-align: center;
                    background: linear-gradient(to left top, #dc000c, #ff4b7c);
                    color: #fff;
                    box-sizing: border-box;
                    box-shadow: 0px 20px 30px rgba(247, 144, 170, 0.49);
                    cursor: pointer;
                }
            }
            .creativity {
                width: 100%;
                .video {
                    text-align: center;
                    .video-items {
                        width: 80%;
                        text-align: right;
                        margin: 1vw auto;
                        .btn {
                            font-size: 26px;
                            color: #A3A1B2;
                            margin-left: 6px;
                            &.video-selected {
                                transition: all .3s linear;
                                color: #FF4B7C;
                                position: relative;
                                padding-left: 12px;
                                &::before {
                                    content: '';
                                    display: inline-block;
                                    border: 10px solid transparent;
                                    border-left-color: #FF4B7C;
                                    position: absolute;
                                    top: 4px;
                                    left: -2px;
                                    border-radius: 3px;
                                }
                            }
                        }
                    }
                }
            }
            .shopping-online {
                .stores {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px;
                    .store {
                        width: 380px;
                        text-align: center;
                        background: #fff;
                        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
                        border-radius: 6px;
                        img {
                            height: 222px;
                            border-radius: 6px 6px 0 0;
                        }
                        h2 {
                            font-size: 24px;
                            margin: 2vw 0 0.5vw;
                        }
                        p {
                            font-size: 18px;
                            color: #8B8B8B;
                        }
                        a {
                            display: inline-block;
                            margin: 3vw 0;
                            color: #fff;
                            background: linear-gradient(to right bottom, #FF4B7C, #DC000C);
                            border-radius: 10vw;
                            width: 200px;
                            height: 50px;
                            font-size: 18px;
                            line-height: 50px;
                        }
                    }
                }
            }
            .download {
                .icon-more {
                    display: inline-block;
                    width: 1.458vw;
                    height: 0.9375vw;
                    background: url('../../assets/images/about/icon_more.png') no-repeat center center;
                    background-size: 100% 100%;
                    cursor: pointer;
                }
                ul {
                    display: flex;
                    justify-content: space-around;
                    li {
                        text-align: center;
                        width: 20%;
                        .pic {
                            width: auto;
                            height: 106px;
                            text-align: center;
                            margin: auto;
                            img {
                                vertical-align: bottom;
                                width: auto;
                                height: 100%;
                            }
                        }
                        h2 {
                            font-size: 24px;
                            font-weight: 400;
                            color: #353968;
                            margin: 2vw 0 1vw;
                        }
                        p {
                            color: #353968;
                            font-size: 1.25vw;
                            margin: 1vw 0;
                        }
                    }
                }
            }
        }
    }

    ::-webkit-input-placeholder { /* WebKit browsers */
      color: #779FB7;
    }

    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: #779FB7;
    }

    :-ms-input-placeholder { /* Internet Explorer 10+ */
      color: #779FB7;
    }  

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }
    @media screen and (max-width: 750px) {
        #product{
            .content{
                background-size: 156% auto;
                .searchBox{
                    width: 100% !important;
                    margin:0 !important;
                    display: block;
                    padding: 6px 10px;
                    background-color: #D93C45;
                    .box{
                        display: flex;
                        background-color: #fff;
                        border-radius: 6px;
                        align-items: center;
                        .fontFn(36,height);
                        padding-left: 4%;
                        input{flex: auto;flex-grow: 1;.fontFn(14);}
                        img{width: 10%;flex:none;margin:0 2%;}
                    }
                }
                .banner{
                    width: 100%;
                    .fontFn(170,height);
                    overflow: hidden;
                    position: relative;
                    img{
                        display: block;
                        width: auto;
                        height: 100%;
                        position: absolute;
                        top:50%;
                        left:50%;
                        transform: translate(-50%,-50%);
                        -webkit-transform: translate(-50%,-50%);
                    }
                }
                &>div:not(.banner){
                    width: 100%;
                    margin:4% auto 10%;
                }
                &>div:not(.products){
                    .topic{
                        padding: 0 2.6%;
                        h2{
                            .fontFn(18);
                            &::after{
                                .fontFn(18,width);
                                .fontFn(5,height);
                                top:-2px;
                            }
                        }
                    }
                }
                .products{
                    .topic{
                        margin-bottom: 8%;
                        .icon-line{
                            .fontFn(22,width);
                            .fontFn(6,height);
                        }
                        h2{
                            .fontFn(18);
                        }
                    }
                    .searchBoxRight{
                        display: none;
                    }
                    .list{
                        ul{
                            justify-content: center;
                            li{
                                width: 45.3%;
                                margin:0 2% 4%;
                                h4{.fontFn(12);margin-top: 0;margin-bottom: 6%; }
                                img{
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                    }
                    .btn-more{
                        .fontFn(12);
                        .fontFn(140,width);
                        .fontFn(44,height);
                        .fontFn(44,line-height);
                    }
                }
                .creativity{
                    padding-bottom: 10%;
                    .video{
                        background:url('../../assets/images/phone/icon_side.png') no-repeat -64px 36px;
                        background-size:30%;
                        position: relative;
                        .video-items{
                            position: absolute;
                            right: 5%;
                            top:-16%;
                            .btn{
                                .fontFn(16.5);
                            }
                            .btn.video-selected{
                                padding-left: 10px;
                            }
                            .btn.video-selected::before{
                                .fontFn(6,border-width);
                                left: 0px;
                                top:3px;
                            }
                        }
                        video{width: 89.3%}
                    }
                } 
                .shopping-online{
                    .stores{
                        display: block;
                        text-align: center;
                        .store{
                            display: inline-block;
                            vertical-align: top;
                            width: 89.3%;
                            margin-bottom: 8%;
                            position: relative;
                            img{width: 100%;height: auto}
                            h2{.fontFn(21);font-weight: normal;padding-bottom: 10px;padding-top: 10px;}
                            p{.fontFn(18);font-weight: normal;padding-bottom: 30px;}
                            a{
                                position: absolute;
                                top:0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                margin:0;
                                opacity: 0;
                            }
                        }
                        
                    }
                }
                .download{
                    ul{
                        li{
                            .pic{
                                .fontFn(58,width);
                                .fontFn(58,height);
                            }
                            h2{
                                .fontFn(12);
                                margin:0;
                                padding-top: 30%;
                                padding-bottom: 30%;
                            }
                        }
                    }
                    .icon-more{
                        .fontFn(16,width);
                        .fontFn(10,height);
                    }
                }
            }
        }  

        ::-webkit-input-placeholder { /* WebKit browsers */
          color: #EC9EBD;
        }

        ::-moz-placeholder { /* Mozilla Firefox 19+ */
          color: #EC9EBD;
        }

        :-ms-input-placeholder { /* Internet Explorer 10+ */
          color: #EC9EBD;
        }  
    }

    
</style>